<template>
  <div>
    <b-modal id="modal-require-accept" v-model="showModal" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc body-class="position-relative">
      <img alt="logo" src="~/mlearn/icons/modal/close-modal.svg" class="close pointer position-absolute" @click="hideModal">
      <b-form-checkbox v-model="term">
        Tôi đồng ý với <span><a class="link" href="https://mobiedu.vn/dieu-khoan-dich-vu" target="_blank">Điều khoản và điều kiện sử dụng dịch vụ</a></span> và <span><a class="link" href="https://mobiedu.vn/chinh-sach-bao-mat" target="_blank">Chính sách bảo vệ dữ liệu cá nhân</a></span> của MobiFone
      </b-form-checkbox>
      <div class="action text-center">
        <b-btn class="cancel" @click="hideModal" :disabled="process">Hủy bỏ</b-btn>
        <b-btn class="accept" @click="submit" :disabled="!term || process">Tôi đồng ý</b-btn>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import {loginFacebook, loginGoogle} from '~/common/api/auth'
import {getToken} from '~/common/utils/auth'

export default {
  data () {
    return {
      showModal: false,
      term: true,
      process: false
    }
  },
  computed: {
    showGgRequireAccept () {
      return this.$store.state.showGgRequireAccept
    },
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  created () {
  },
  methods: {
    submit () {
      if (this.showGgRequireAccept) {
        this.loginGoogle()
      } else {
        this.fbAuthUser()
      }
    },
    loginGoogle () {
      if (this.isMobile) {
        const path = this.$route.fullPath
        Cookies.set('redirect', path)
        const url = 'https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email&response_type=token%20id_token&redirect_uri=' + process.env.NUXT_ENV_GOOGLE_REDIECT + '&client_id=' + process.env.NUXT_ENV_GOOGLE_CLIENT_ID + '&nonce=thtfmJzzrwhiG-LYFecUT&&flowName=GeneralOAuthFlow'
        window.location.href = url
      } else {
        this.process = true
        this.$gAuth.signIn()
          .then((googleUser) => {
            const accessToken = googleUser.getAuthResponse().id_token
            const params = {
              accessToken
            }
            loginGoogle(params)
              .then((response) => {
                this.$emit('Login')
                this.$store.dispatch('acceptCustomer', {
                  email: response.data.email,
                  net_type: 'google'
                })
                this.$store.commit('SET_AUTHENTICATED', true)
                this.$store.commit('SET_TOKEN', response.data.accessToken)
                this.$store.commit('SET_USER', {
                  email: response.data.email,
                  fullName: response.data.fullName,
                  userName: response.data.userName,
                  avatar: response.data.avatar,
                  uuid: response.data.uuid
                })
                this.connectSocket()
                this.$router.go(this.$router.currentRoute)
              })
              .catch(() => {
              })
              .finally(() => {
                this.process = false
              })
          })
          .catch(() => {
          })
          .finally(() => {
            this.process = false
          })
      }
    },
    connectSocket () {
      this.$socket.io.opts.query = {
        token: getToken()
      }
      this.$socket.connect()
    },
    fbAuthUser () {
      window.FB.getLoginStatus((response) => {
        if (response.status !== 'connected') {
          this.process = true
          return window.FB.login((response) => {
            this.handleFbResponse(response)
          }, {
            scope: 'public_profile,email',
            enable_profile_selector: true,
            auth_type: 'rerequest',
            return_scopes: true
          })
        } else {
          this.handleFbResponse(response)
        }
      })
    },
    handleFbResponse (response) {
      if (response.status === 'connected') {
        const accessToken = response.authResponse.accessToken
        // const facebook_id = response.authResponse.userID
        const params = {
          accessToken
        }
        loginFacebook(params)
          .then((response) => {
            this.$emit('Login')
            this.$store.dispatch('acceptCustomer', {
              net_account: response.data.fbId,
              net_type: 'facebook'
            })
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.commit('SET_TOKEN', response.data.accessToken)
            this.$store.commit('SET_USER', {
              email: response.data.email,
              fullName: response.data.fullName,
              userName: response.data.userName,
              avatar: response.data.avatar,
              uuid: response.data.uuid
            })
            this.connectSocket()
            this.$router.go(this.$router.currentRoute)
          })
          .catch(() => {
          })
          .finally(() => {
            this.process = false
          })
      } else {
        this.process = false
        return false
      }
    },
    hideModal () {
      if (!this.process) {
        this.showModal = false
      }
    }
  }
}
</script>

<style lang="scss">
#modal-require-accept {
  &___BV_modal_outer_ {
    z-index: 999999 !important;

    .modal-dialog {
      max-width: 500px;
      margin: 0 auto;
      @media (max-width: 576px) {
        width: 90%;
      }

      .modal-content {
        border-radius: 5px;
      }

      .modal-body {
        padding: 35px 25px;

        .close {
          right: 10px;
          top: 10px;
          @media (max-width: 576px) {
            width: 15px;
            height: 15px;
            right: 10px;
            top: 10px;
          }
        }

        .action {
          display: flex;
          margin-top: 30px;
          align-items: center;
          justify-content: center;
          gap: 20px;
        }

        .accept, .cancel {
          cursor: pointer;
          font-size: 14px;
          text-align: center;
          color: var(--white);
          background: #0374FC;
          border-radius: 3px;
          border: 2px solid #0374FC;
          padding: 6px 16px;
          text-transform: uppercase;
          margin: 0 auto;
          width: 150px;
          font-weight: 500;
        }

        .cancel {
          color: #0374FC;
          background: var(--white);
          border: 2px solid #0374FC;
        }
      }
    }

    .link {
      color: -webkit-link;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

</style>
