<template>
  <footer>
    <div class="container">
      <img alt="logo" src="~/mlearn/icons/footer/logo.svg" class="logo pointer" @click="goToPage('/')">
      <div class="row">
        <div class="col-lg-9 col-xl-5">
          <div class="company-info">
            <div class="name">Trung tâm Dịch vụ số MobiFone – Chi nhánh Tổng Công ty Viễn thông MobiFone</div>
            <div><span>Địa chỉ: </span>Số 1 Phạm Văn Bạch, Phường Yên Hòa, Quận Cầu Giấy, Hà Nội</div>
            <div><span>Giấy chứng nhận ĐKKD: </span>0100686209-087 do Sở KHĐT thành phố Hà Nội<br>Cấp lần đầu ngày 29/10/2008<br>Thay đổi lần thứ 6 ngày 16/06/2023</div>
            <div><span>Chịu trách nhiệm nội dung: </span>Ông Lê Quang Minh - Giám đốc Trung Tâm</div>
            <div><span>Hợp tác phát triển và vận hành sản phẩm: </span>Công Ty Cổ Phần DATANOVA Việt Nam</div>
            <div class="d-none d-lg-block"><img alt="notify" src="~/mlearn/icons/footer/notify.svg" class="notify"></div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-3 line-mobile">
          <div class="block-data">
            <div class="title">THÔNG TIN</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item">
                <nuxt-link to="/thong-tin-khac?tab=gioi-thieu">Giới thiệu</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/thong-tin-khac?tab=dieu-khoan-su-dung">Điều khoản sử dụng</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/thong-tin-khac?tab=chinh-sach-bao-mat">Chính sách bảo mật</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/">Dịch vụ nằm trong hệ sinh thái mobiedu.vn</nuxt-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-2 line-mobile">
          <div class="block-data">
            <div class="title">DỊCH VỤ CUNG CẤP</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item">
                <nuxt-link to="/">Hỏi bài</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/khoa-hoc">Khóa học</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/tai-lieu">Tài liệu</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/trac-nghiem">Bộ đề</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/blog">Blog</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/goi-cuoc">Gói cước</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/tu-hoc">Tự học</nuxt-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 order-lg-last col-xl-2 line-mobile">
          <div class="block-data">
            <div class="title">HỖ TRỢ KHÁCH HÀNG</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item"><img alt="call" src="~/mlearn/icons/footer/call.svg" class="call"> 1900866648</li>
            </ul>
          </div>
          <div class="block-data d-none d-xl-block">
            <div class="title">TẢI ỨNG DỤNG MLEARN</div>
            <ul class="list-item list-unstyled">
              <li class="item form-data">
                <img alt="qrcode" src="~/mlearn/icons/footer/qrcode.png" class="qrcode">
                <div class="content platform">
                  <div>
                    <img alt="chplay" src="~/mlearn/icons/footer/chplay.svg" class="chplay w-100">
                  </div>
                  <div class="pt-1">
                    <img alt="appstore" src="~/mlearn/icons/footer/appstore.svg" class="appstore w-100">
                  </div>
                </div>
              </li>
              <li class="item note">Quét mã QR code để cài đặt</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-2 d-block d-xl-none line-mobile">
          <div class="block-data">
            <div class="title">TẢI ỨNG DỤNG MLEARN</div>
            <ul class="list-item list-unstyled mb-0">
              <li class="item form-data">
                <img alt="qrcode" src="~/mlearn/icons/footer/qrcode.png" class="qrcode">
                <div class="content platform">
                  <div>
                    <img alt="chplay" src="~/mlearn/icons/footer/chplay.svg" class="chplay">
                  </div>
                  <div class="pt-2">
                    <img alt="appstore" src="~/mlearn/icons/footer/appstore.svg" class="appstore">
                  </div>
                </div>
                <div class="d-block d-lg-none align-self-end"><img alt="notify" src="~/mlearn/icons/footer/notify.svg" class="notify"></div>
              </li>
              <li class="item note">Quét mã QR code để cài đặt</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="copy-right"><img alt="copy-right" src="~/mlearn/icons/footer/copy-right.svg"> Copyright 2020 - MobiFone</div>
    </div>
    <template v-if="!user">
      <ModalLogin/>
      <ModalRegister/>
      <ModalRequireAccept/>
      <ModalForget/>
    </template>
    <ModalQuestion/>
    <ModalRedirectSub/>
    <ModalVerifyGuru v-if="!isVerifiedMobile"/>
    <ModalVerifyMobile/>
    <ModalVerifyClass/>
    <ModalSuggestSub/>
    <BackToTop :right="isMobile ? '10%' : '50px'"/>
    <ChatAi/>
  </footer>
</template>

<script>
import ModalForget from '~/components/mlearn/modal/forget.vue'
import ModalLogin from '~/components/mlearn/modal/login.vue'
import ModalRegister from '~/components/mlearn/modal/register.vue'
import ModalQuestion from '~/components/mlearn/modal/add-question.vue'
import ModalRedirectSub from '~/components/mlearn/modal/sub.vue'
import ModalVerifyGuru from '~/components/mlearn/modal/verify-guru.vue'
import ModalVerifyMobile from '~/components/mlearn/modal/verify-mobile.vue'
import ModalVerifyClass from '~/components/mlearn/modal/verify-class.vue'
import ModalSuggestSub from '~/components/mlearn/modal/suggest-sub.vue'
import ModalRequireAccept from '~/components/mlearn/modal/require-accept.vue'
import ChatAi from '~/components/mlearn/chat-ai/index.vue'

export default {
  components: {
    ChatAi,
    ModalLogin,
    ModalRegister,
    ModalForget,
    ModalQuestion,
    ModalRedirectSub,
    ModalVerifyMobile,
    ModalVerifyGuru,
    ModalVerifyClass,
    ModalSuggestSub,
    ModalRequireAccept
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isMobile () {
      return this.$store.state.isMobile
    },
    isVerifiedMobile () {
      return this.$store.state.isVerifiedMobile
    }
  },
  methods: {
    goToPage (path = '/') {
      this.$router.push({path})
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  background: #0A4874;
  color: rgba(255, 255, 255, 0.8);
  padding: 28px 0 15px;
  @media (max-width: 576px) {
    padding: 15px 0 10px;
  }

  .logo {
    max-height: 42px;
    @media (max-width: 576px) {
      max-height: 36px;
    }
  }

  .row {
    .line-mobile {
      @media (max-width: 576px) {
        border-top: 1px solid rgba(255, 255, 255, .2);
      }
      // @media (max-width:991px) {
      //   border-top: 1px solid rgba(255, 255, 255, .2);
      // }
    }

    > [class*="col-"] {
      padding-top: 2rem;
      @media (max-width: 576px) {
        padding-top: 1rem;
      }

      .company-info {
        padding-right: 50px;
        @media (max-width: 1366px) {
          padding-right: unset;
        }
        @media (max-width: 576px) {
          padding-right: unset;
        }

        > div {
          color: rgba(255, 255, 255, .6);
          font-size: 14px;
          white-space: break-spaces;
          margin-bottom: 10px;
          @media (max-width: 576px) {
            margin-bottom: 8px;
          }

          &.name {
            font-weight: bold;
            color: rgba(255, 255, 255, 0.8);
            font-size: 16px;
            @media (max-width: 576px) {
              font-size: 14px;
            }
          }

          span {
            font-weight: bold;
            color: rgba(255, 255, 255, .8);
            @media (max-width: 576px) {
            }
          }

          .notify {
            max-height: 60px;
            @media (max-width: 576px) {
              max-height: 40px;
            }
          }
        }
      }

      .block-data {
        margin-top: 35px;
        @media (max-width: 576px) {
          margin-top: 25px;
        }

        &:first-child {
          margin-top: 0;
          @media (max-width: 576px) {
          }
        }

        .title {
          font-weight: bold;
          color: rgba(255, 255, 255, .6);
          text-transform: uppercase;
          font-size: 14px;
          margin-bottom: 18px;
          @media (max-width: 576px) {
            margin-bottom: 10px;
          }
        }

        .list-item {
          margin-bottom: 0;
          @media (max-width: 576px) {
            white-space: nowrap;
          }

          .item {
            font-weight: 500;
            font-size: 14px;
            color: rgba(255, 255, 255, .8);
            margin-bottom: 8px;
            @media (max-width: 576px) {
              display: inline-block;
              margin-right: 12px;
              &:last-child {
                margin-right: 0;
              }
            }

            &:last-child {
              // margin-bottom: 0;
              @media (max-width: 576px) {
              }
            }

            &.note {
              font-style: italic;
              font-weight: normal;
              font-size: 12px;
              color: rgba(255, 255, 255, .6);
              @media (max-width: 576px) {
              }
            }

            a {
              font-weight: 500;
              font-size: 14px;
              color: rgba(255, 255, 255, .8);
            }

            .platform {
              margin-left: 10px;
              @media (max-width: 576px) {
              }

              img {
                margin-bottom: 10px;
                @media (max-width: 576px) {
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .notify {
              max-width: 120px;
            }
          }
        }
      }
    }
  }

  .line {
    height: 1px;
    margin: 12px 0;
    background: rgba(255, 255, 255, .2);
    @media (max-width: 576px) {
    }
  }

  .copy-right {
    font-size: 14px;
    color: rgba(255, 255, 255, .6);
    @media (max-width: 576px) {
    }
  }

  .qrcode {
    width: 74px;
    height: 74px;
    border-radius: 5px;
  }
}
</style>
